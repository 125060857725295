import React from 'react';
import { useContentfulAssets } from '../../../hooks/useContentfulAssets';
import * as Styled from './imageWithPathSquareStyles';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { useContentfulImages } from '../../../hooks/useContentfulImages';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
  isYoutubeLink,
} from '../../../utils';

const ImageWithPath = ({ sectionData, location }) => {
  React.useEffect(async () => {
    if (sectionData?.elementId === location?.hash?.replace('#', '')) {
      const waitedID = await new Promise((resolve) => {
        document?.getElementById(sectionData?.elementId) && resolve(true);
      });

      if (waitedID) {
        setTimeout(() => {
          window.location.href = `#${sectionData?.elementId}`;
        }, 500);
      }
    }
  }, [sectionData?.elementId]);

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),

      [BLOCKS.UL_LIST]: (node, children) => (
        <Styled.UnorderedList>{children}</Styled.UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListItemArrow>{children}</Styled.ListItemArrow>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.LinkStyle
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                 process.env.GATSBY_DOMAIN_URL + node?.data?.uri
              )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.LinkStyle>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        return asset.node.file.url.includes('image') ? (
          asset.node.description ? (
            <Styled.LinkStyle
              href={
                isExternalUrlHref(asset?.node?.description)
                  ? asset?.node?.description
                  : addTrailingSlash(
                      process.env.GATSBY_DOMAIN_URL + asset?.node?.description
                    )
              }
              target={
                isExternalUrl(asset?.node?.description) ? '_blank' : '_self'
              }
            >
              <Styled.ImageStyle
                src={asset.node.file.url}
                alt={asset.node.title}
              />
            </Styled.LinkStyle>
          ) : (
            <Styled.ImageStyle
              src={asset.node.file.url}
              alt={asset.node.title}
            />
          )
        ) : (
          <></>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return isYoutubeLink(node.data.uri) ? (
          <Styled.VideoContainer>
            <Styled.Iframe src={node.data.uri} frameborder="0"></Styled.Iframe>
          </Styled.VideoContainer>
        ) : (
          <Styled.LinkStyle
            href={
              isExternalUrlHref(node?.data?.uri)
                ? node?.data?.uri
                : addTrailingSlash(
                   process.env.GATSBY_DOMAIN_URL + node?.data?.uri
                )
            }
            target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
          >
            {children}
          </Styled.LinkStyle>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <React.Fragment>
      {sectionData?.elementId?.trim() && (
        <Styled.SpanStyle id={sectionData?.elementId?.trim()} />
      )}
      {sectionData?.header?.trim() && (
        <Styled.HeaderH2>{sectionData?.header}</Styled.HeaderH2>
      )}
      {sectionData?.contentDetails &&
        documentToReactComponents(
          JSON.parse(sectionData?.contentDetails?.raw),
          optionsMainStyle
        )}
    </React.Fragment>
  );
};

export default ImageWithPath;
